@media (max-width: 480px) {
    .landingpage {
        .content {
            .image {
                position: absolute;
                top: 0.5rem;
                left: -3rem;
            }
            .heading {
                top: 4vh !important;
                left: 66vw !important;
                right: auto;
                height: 300px;
                span {
                    letter-spacing: 0px;
                    font-size: 3vh;
                    writing-mode: vertical-lr;
                    -webkit-text-orientation: upright;
                    text-orientation: upright;
                    text-align: center;
                }
            }
            .designation {
                top: 46vh;
                left: 20vw;
                font-size: 5vw;
            }
            .description {
                top: 49vh;
                left: 12vw;
                font-size: 3.5vw;
                letter-spacing: 1px;
                width: 80vw;
            }
            .link {
                top: 62vh;
                left: 9vw;
            }
        }
    }
    .skill {
        .image-skill-group {
            .skill-image {
                width: 250px;
                height: 250px;
            }
        }
    }
    .Skill-overlay {
        .content {
            margin: 2rem !important;
            h2 {
                font-size: 15vw;
            }
            p {
                font-size: 5vw !important;
                margin: 0 0 0 0 !important;
            }
        }
        img {
            width: 250px;
            height: 250px;
        }
    }
}
