.skill {
    position: absolute;
    bottom: 0;
    right: 5vw;
    user-select: none;
    .image-skill-group {
        position: relative;
        top: 0;
        left: 0;
        filter: grayscale(100);
        transition: all 0.5s;
        img {
            width: 450px;
            height: 450px;
            transition: all 0.5s ease-in-out;
        }
        &:hover {
            filter: none;
        }
        .view {
            opacity: 1;
        }
        .hidden {
            opacity: 0;
        }
        .Overly {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .null-filter {
        filter: none !important;
    }
}
.Skill-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgb(0, 0, 0);
    transition: clip-path 1s ease-in-out;
    opacity: 0;
    clip-path: circle(18% at 82% 70%);
    .content {
        margin: 5rem;
        padding: 0 0 0 1rem;
        h2 {
            margin: 7rem 0 2rem 0;
            // font-family: "CarnivaleeFreakshow", Fallback, sans-serif;
            font-size: 6vw;
            font-weight: 200;
            color: #fff;
        }
        p {
            // font-family: "CarnivaleeFreakshow", Fallback, sans-serif;
            font-family: "Blackford", Fallback, sans-serif;
            font-size: 2.5vw;
            margin: 0 450px 0 0;
            text-align: center;
            opacity: 1;
        }
    }
    img {
        width: 450px;
        height: 450px;
    }
}
.display {
    opacity: 0.9;
    clip-path: circle(100%);
}
