@media (min-width: 481px) and (max-width: 767px) {
    .landingpage {
        .content {
            .designation {
                top: 18rem;
                left: 12rem;
                font-size: 5vw;
            }
            .heading {
                top: 4vh !important;
                left: 66vw !important;
                right: auto;
                height: 300px;
                span {
                    letter-spacing: 0px;
                    font-size: 3vh;
                    writing-mode: vertical-lr;
                    -webkit-text-orientation: upright;
                    text-orientation: upright;
                    text-align: center;
                }
            }
            .description {
                top: 19.5rem;
            }
            .link {
                top: 70vh;
                left: 5vw;
            }
        }
    }
    .skill {
        .image-skill-group {
            .skill-image {
                width: 250px;
                height: 250px;
            }
        }
    }
    .Skill-overlay {
        img {
            width: 250px;
            height: 250px;
        }
    }
}
