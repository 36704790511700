/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
    .landingpage {
        .content {
            .image {
                position: absolute;
                top: 0rem;
                left: -5rem;
                img {
                    height: 40vh;
                }
            }
            .email {
                top: 3vh;
                right: 5vw;
            }
            .heading {
                top: 42vh;
                left: auto;
                right: 6vw;
                letter-spacing: 4px;
                font-size: 7vw;
            }
            .designation {
                top: 25rem;
                left: 6rem;
                font-size: 5vw;
            }
            .description {
                top: 27rem;
                left: 4vw;
                width: 90vw;
                font-size: 2.5vw;
                text-align: justify;
            }
            .link {
                top: 60vh;
                left: 3vw;
                span {
                    margin: 0 1rem;
                    font-size: 1rem;
                }
            }
        }
    }
    .skill {
        .image-skill-group {
            .skill-image {
                width: 300px;
                height: 300px;
            }
        }
    }
    .Skill-overlay {
        img {
            width: 300px;
            height: 300px;
        }
    }
}
