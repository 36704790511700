.content {
    a {
        text-decoration: none;
        color: #000;
    }
    .email {
        position: absolute;
        top: 3.5rem;
        right: 4rem;
        font-size: 2vw;
        span {
            font-size: 1.5vw;
        }
        &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 2px;
            bottom: 0;
            right: 0;
            background-color: rgb(0, 0, 0);
            visibility: hidden;
            transition: all 0.3s ease-in-out;
        }
        &:hover::before {
            visibility: visible;
            width: 100%;
        }
        a {
            cursor: none;
        }
    }
    .image {
        position: absolute;
        top: 3px;
        left: -8rem;
        img {
            height: 100vh;
        }
    }
    .heading {
        // mix-blend-mode: lighten;
        position: absolute;
        top: 8rem;
        left: 28vw;
        font-family: "CarnivaleeFreakshow", Fallback, sans-serif;
        font-size: 6vw;
        letter-spacing: 10px;
        transition: all 2s ease-in-out;
        background: linear-gradient(135deg, #00fffc 0%, #2302ec 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .designation {
        position: absolute;
        top: 26vh;
        left: 34vw;
        font-size: 3.5vw;
        transition: all 0.5s ease-in-out;
    }
    .description {
        position: absolute;
        top: 33vh;
        left: 36vw;
        font-size: 1.5vw;
        letter-spacing: 4px;
        width: 55vw;
        shape-outside: url("https://assets.codepen.io/9632/css-tricks-logo-shadow_1.png");
        shape-image-threshold: 0.6;
    }
    .link {
        position: absolute;
        top: 47vh;
        left: 30vw;
        span {
            margin: 0 3rem;
            font-size: 2.5rem;
            transition: all 0.5s ease-in-out;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                width: 0;
                height: 2px;
                bottom: 0;
                left: 0;
                background-color: rgb(0, 0, 0);
                visibility: hidden;
                transition: all 0.3s ease-in-out;
            }
            &:hover {
                &::before {
                    visibility: visible;
                    width: 100%;
                }
                // letter-spacing: 5px;
            }
        }
    }
}
.bgimg {
    background-image: url("./../../../Asserts/Image/logo.png");
    background-repeat: repeat;
    background-size: 16px 9px;
    // background-size: 32px 18px;
    filter: invert(3%);
    height: 100vh;
}
