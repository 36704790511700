.image-bg {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    img.background-image {
        position: relative;
        // transition: all 0.7s ease-in-out;
        // filter: invert(10%);
        &:nth-child(odd) {
            transform: rotate(180deg);
        }
        &:hover {
            filter: invert(100%);
        }
    }
}
