@font-face {
    font-family: "CarnivaleeFreakshow";
    src: url("./Asserts/Fonts/Carnevalee\ Freakshow.woff2") format("woff2"),
        url("./Asserts/Fonts/CarnivaleeFreakshow.woff") format("woff"),
        url("./Asserts/Fonts/Carnevalee\ Freakshow.ttf") format("truetype");
}

@font-face {
    font-family: "Blackford";
    src: url("./Asserts/Fonts/Blackford.woff2") format("woff2"),
        url("./Asserts/Fonts/BlackfordRegular.woff") format("woff"),
        url("./Asserts/Fonts/Blackford.ttf") format("truetype");
}
html,
body {
    cursor: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-family: "Blackford", Fallback, sans-serif;
    position: relative;
}

* {
    box-sizing: border-box;
}
