@media (min-width: 768px) and (max-width: 979px) {
    .landingpage {
        .content {
            .image {
                position: absolute;
                top: 0rem;
                left: -5rem;
                img {
                    height: 50vh;
                }
            }
            .email {
                top: 3vh;
                right: 5vw;
            }
            .heading {
                top: 50vh;
                left: auto;
                right: 4vw;
                letter-spacing: 7px;
                font-size: 4vw;
            }
            .designation {
                top: 20rem;
                left: 20rem;
                font-size: 4vw;
            }
            .description {
                top: 22rem;
                left: auto;
                width: 90vw;
                right: 1vw;
            }
            .link {
                top: 26rem;
                right: 33rem;
                span {
                    margin: 0 1rem;
                    font-size: 1rem;
                }
            }
        }
    }
    .skill {
        .image-skill-group {
            .skill-image {
                width: 350px;
                height: 350px;
            }
        }
    }
    .Skill-overlay {
        img {
            width: 350px;
            height: 350px;
        }
    }
}
